.intro {
  position: relative;
  display: flex;
  font-family: "Nunito";
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
}

.i-left {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
}

.i-name {
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  line-height: 1.5;
  gap: 2;
}

.i-name span {
  display: block;
}

.i-name span:first-child {
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--orange);
}

.i-name span:nth-child(2) {
  font-size: 1.1rem;
  margin-top: 1rem;
  font-weight: 600;
  color: var(--gray);
}

.i-icons {
  display: flex;
  gap: 0rem;
  margin-top: 1rem;
}

.i-icons > * {
  transform: scale(0.3);
}

.i-icons > *:hover {
  cursor: pointer;
}
.i-right {
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  min-height: 50vh;
}
.blob-container {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  position: relative;
}
.blob-main {
  position: relative;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}

.main-blob {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  margin-right: 0%;
  justify-content: center;
}

.small-blob {
  align-items: center;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 0;
}
.profilePic {
  align-items: center;
  justify-content: center;
  position: relative;
  display: flex;
  width: 300px;
  height: 380px;
  margin-left: 3rem;
  margin-bottom: 4rem;
  clip-path: circle(60% at 30% 45%);
  z-index: 9;
}
.portfolio-img {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.portfolio-img.loaded {
  opacity: 1;
}
.floating-developer {
  margin-right: -2rem;
}
.floating-developer,
.floating-technology,
.floating-performance {
  position: absolute;
  border-radius: 10px;
  z-index: 9;
}
.floating-developer,
.floating-performance {
  background-color: rgb(255, 255, 255);
}
.floating-technology img {
  transform: scale(2.5);
  width: 40px;
  height: auto;
  z-index: 9;
}
.floating-container {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.floating-performance img,
.floating-developer img {
  width: 2rem;
  margin-right: 1rem;
}

.floating-text {
  display: flex;
  flex-direction: column;
}

.floating-text span {
  font-size: 0.9rem;
  color: rgb(61, 87, 103);
}

.floating-blur1,
.floating-blur {
  position: absolute;
  border-radius: 50%;
  filter: blur(72px);
}

.floating-blur1 {
  background: #6fc0efca;

  top: 5%;
}

.floating-blur {
  background: #6fc0efaf;
  top: -15%;
}

@media screen and (max-width: 1320px) {
  .intro {
    flex-direction: column;
    position: relative;
    display: flex;
    font-family: "Nunito";
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    min-height: 100vh;
  }
  .i-right {
    justify-content: center;
    min-height: auto;
  }
  .i-left {
    padding-top: 10rem;
  }
  .i-name {
    font-size: 1.5rem;
    line-height: 1.4;
  }
  .i-name span:first-child {
    font-size: 2rem;
  }
  .i-name span:nth-child(2) {
    font-size: 1rem;
  }
  .i-icons {
    margin: 0 auto;
    gap: 0rem;
    margin-top: -0.5rem;
  }
  .i-icons > * {
    transform: scale(0.2);
    margin-bottom: 2rem;
    position: relative;
  }
  .floating-text span {
    font-size: 0.7rem;
  }

  .floating-developer,
  .floating-technology,
  .floating-performance {
    font-size: 1rem;
  }
  .floating-technology img {
    height: auto;
    z-index: 9;
  }
  .floating-technology {
    margin-left: -4rem;
    margin-top: -2rem;
    height: auto;
    z-index: 9;
  }
  .floating-developer {
    margin-top: -2rem;
    margin-right: -2rem;
  }
  .floating-performance {
    margin-left: -2rem;
  }
}

@media screen and (max-width: 560px) {
  .intro {
    flex-direction: column;
    position: relative;
    display: flex;
    font-family: "Nunito";
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    min-height: 100vh;
  }
  .i-right {
    justify-content: center;
    min-height: auto;
  }
  .i-left {
    padding-top: 10rem;
  }
  .i-right {
    transform: scale(0.7);
  }
  .i-name span:first-child {
    font-size: 1.5rem;
  }
  .floating-technology {
    top: 30% !important;
    height: auto;
    z-index: 9;
  }
}
@media screen and (max-width: 420px) {
  .intro {
    flex-direction: column;
    position: relative;
    display: flex;
    font-family: "Nunito";
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    min-height: 100vh;
  }
  .i-right {
    justify-content: center;
    min-height: auto;
  }
  .i-left {
    padding-top: 10rem;
  }
  .i-right {
    transform: scale(0.6);
  }
  .i-name span:first-child {
    font-size: 1.3rem;
  }
  .i-name span:nth-child(2) {
    font-size: 0.8rem;
  }
  .floating-technology {
    margin-left: -4rem;
    margin-top: -2rem;
    height: auto;
    z-index: 9;
  }
  .floating-developer {
    margin-top: -2rem;
    margin-right: -2rem;
  }
  .floating-performance {
    margin-left: -2rem;
  }
}
@media screen and (max-width: 320px) {
  .intro {
    flex-direction: column;
    position: relative;
    display: flex;
    font-family: "Nunito";
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    min-height: 100vh;
  }
  .i-right {
    justify-content: center;
    min-height: auto;
  }
  .i-left {
    padding-top: 10rem;
  }
  .i-right {
    transform: scale(0.5);
  }
  .i-name span:first-child {
    font-size: 1.3rem;
  }
  .i-name span:nth-child(2) {
    font-size: 0.8rem;
  }
  .floating-technology {
    height: auto;
    z-index: 9;
  }
}
